// Chakra imports
import {
  Box,
  Center,
  Container,
  Text,
} from "@chakra-ui/react";

import "/node_modules/video-react/dist/video-react.css";

import HeaderTop from "../../components/HeaderTop";
import mainvisual from "../../images/img-main.png";

function Top() {
  let catchTitle =
    "マンションのエントランスドアを解錠できるAPIを活用してみませんか";
  let catchBody =
    "ライナフコネクトを活用すれば、自社システムにエントランスドア解錠機能を追加して、入居者不在のときでも、お部屋の前まで自社サービスをお届けすることができます。";
  let newSiteLink = 
    "ライナフコネクトサービスページはこちら";


  return (
    <>
      <HeaderTop />
      <Box
        style={{ background: `url(${mainvisual}) center/cover no-repeat` }}
        backdropFilter="invert(100%)"
      >
        <Box backgroundColor="rgba(255,255,255,0.3)" pb={150}>
          <Container maxW="1200px" pt="200px">
            <Box width={{md:"600px",sm:"100%"}}>
              <Text fontSize={{md:"4xl",sm:"3xl"}} mb="30px" fontWeight="bold">
                {catchTitle}
              </Text>
              <Text fontSize={{md:"2xl",sm:"xl"}} mb="50px">
                {catchBody}
              </Text>
              <Box bg="rgba(0,86,0,1)" rounded="base" color="white">
              <a href="https://lp.linough.com/linough-connect">
                <Center>
                <Text fontSize={{md:"2xl",sm:"xl"}} p="4">
                  {newSiteLink}
                </Text>
                </Center>
              </a>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>

      <Box>
        <Container maxW="1200px" pt={{md:"120px",sm:"50px"}}>
        </Container>
      </Box>

    </>
  );
}
export default Top;
